<template>
   <section class="events__area pt-115 pb-120 p-relative">
      <div class="events__shape">
         <img class="events-1-shape" src="../../assets/img/events/events-shape.png" alt="">
      </div>
      <div class="container">
         <div class="row">
            <div class="col-xxl-4 offset-xxl-4">
               <div class="section__title-wrapper mb-60 text-center">
                  <h2 class="section__title">{{ $t('tugeemel') }} 
                     <span class="yellow-bg yellow-bg-big">{{ $t('answe_questions') }}<img
                           src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                  <!-- <p>We found 13 events available for you.</p> -->
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="events__item mb-10 hover__active">
                  <div class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                     <div class="events__content">
                        <h3 class="events__title">
                           <router-link v-if="this.$i18n.locale === 'mn'" :to="'/event-details?id=' + this.id1">Шинэ эрин, Шинэ эхлэл сургуулиудад бэлддэг үү?</router-link>
                           <router-link v-if="this.$i18n.locale === 'en'" :to="'/event-details?id=' + this.id1">Do you prepare the students for New era and New Beginning schools?</router-link>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="events__item mb-10 hover__active">
                  <div class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                     <div class="events__content">
                        <h3 class="events__title">
                           <router-link v-if="this.$i18n.locale === 'mn'" :to="'/event-details?id=' + this.id2">Монгол тэмүүлэл сургуульд бэлддэг үү?</router-link>
                           <router-link v-if="this.$i18n.locale === 'en'" :to="'/event-details?id=' + this.id2">Do you prepare the students for Mongol Aspiration school? </router-link>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div class="row">
            <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="events__item mb-10 hover__active">
                  <div class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                     <div class="events__content">
                        <h3 class="events__title">
                           <router-link v-if="this.$i18n.locale === 'mn'" :to="'/event-details?id=' + this.id3">Гадаад, дотоодын олимдиадуудад бэлддэг үү?</router-link>
                           <router-link v-if="this.$i18n.locale === 'en'" :to="'/event-details?id=' + this.id3">Do you prepare the students for International and domestic competitions?</router-link>
                        </h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!-- <div class="row">
            <div v-for="(question,index) in arrayData.splice(0, 3)" :key="index"
               class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
               <div class="events__item mb-10 hover__active">
                  <div class="events__item-inner d-sm-flex align-items-center justify-content-between white-bg">
                     <div class="events__content">
                        <h3 class="events__title">
                           <router-link :to="'/event-details?id=' + question.id">{{question.question}}</router-link>
                        </h3>
                     </div>
                     <div class="events__more">
                        <router-link :to="'/event-details?id=' +  + question.id" class="link-btn">
                           Дэлгэрэнгүй
                           <i class="far fa-arrow-right"></i>
                           <i class="far fa-arrow-right"></i>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div> -->
      </div>
   </section>
</template>

<script>
   import axios from 'axios'
   import config from '@/config/index'
   export default {
      name: 'EventArea',
      created() {
         this.getListQuestion()
      },
      data() {
         return {
            arrayData: [],
            id1: 1,
            id2: 2,
            id3: 3
         }
      },
      methods: {
         getListQuestion(user_info) {
            var self = this
            var payload = {
               user_info_id : user_info
         }
            axios.get(config.BASE_SERVER_PATH + '/getFrequentlyQuestionWeb',payload)
               .then(function (response) {
                  console.log(response.data.result, 'dms')
                  self.arrayData = response.data.result
               })
               .catch(function (error) {
                  console.log(error);
               });
         },
      }
   };
</script>