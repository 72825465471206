<template>
    <Header header__white="header__white" />
    <Breadcrumb :title="$t('news')" :subtitle="$t('news')" />
    <div class="service_detail_body m-b-100 inline_height">
      {{ $t('news') }}
    </div>
    <Footer footerPadding="true"/>
  </template>
  
  <script>
  import Header from '../components/Home/Header.vue';
  import Breadcrumb from '../components/common/Breadcrumb.vue';
  import Footer from '../components/Home/Footer.vue';
  
  export default {
     name:'BlogPage',
     components:{
        Header,
        Breadcrumb,
        Footer,
     }
  }
  </script>