<template>
 <section class="page__title-area pt-120 pb-90">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="../../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="../../assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-7" src="../../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__wrapper">
               <div class="page__title-content mb-25">
                  <div class="page__title-breadcrumb">                            
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/courses">{{ $t('academics') }}</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">{{detail.title}}</li>
                        </ol>
                        </nav>
                  </div>
                  <span class="page__title-pre" v-if="detail.category">{{detail.category.title}}</span>
                  <h5 class="page__title-3" v-if="detail.title">{{detail.title}}</h5>
               </div>
               <div class="course__meta-2 d-sm-flex mb-30">
                  <div class="course__update mr-80 mb-30">
                     <h5>Хичээл эхэлсэн огноо:</h5>
                     <p>2022-07-11</p>
                  </div>
               </div>
               <div class="course__img w-img mb-30">
                  <img v-if="detail.img_url" :src="'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/'+ detail.img_url" alt="">
               </div>
               <div class="course__tab-2 mb-45">
                  <ul class="nav nav-tabs" id="courseTab" role="tablist">
                     <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> <i class="icon_ribbon_alt"></i> <span>Тайлбар</span> </button>
                     </li>
                     <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link" id="member-tab" data-bs-toggle="tab" data-bs-target="#member" type="button" role="tab" aria-controls="member" aria-selected="false"> <i class="fal fa-user"></i> <span>Сурагчид</span> </button>
                     </li> -->
                     </ul>
               </div>
               <div class="course__tab-content mb-95">
                  <div class="tab-content" id="courseTabContent">
                     <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                        <div class="course__description">
                           <h3>Сургалтын талаар</h3>
                           <p>{{detail.description}}</p>
                           <div class="course__description-list mb-45">
                           </div>
                        </div>
                     </div>

                     <!-- CurriculumTab start -->
                     <CurriculumTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
                     <ReviewTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
                     <MemberTab/>
                     <!-- CurriculumTab end -->
                   
                  </div>
               </div>
            </div>
         </div>

       <!-- course side bar start -->
       <CourseSidebar :detail="detail"/>
       <!-- course side bar end -->

      </div>
   </div>
</section>
</template>

<script>
// import SliderCourses from './SliderCourses.vue';
import CourseSidebar from './CourseSidebar.vue';
import CurriculumTab from './CurriculumTab.vue';
import ReviewTab from './ReviewTab.vue';
import MemberTab from './MemberTab.vue';

export default {
   name:'CourseDetailsArea',
   components:{
      // SliderCourses,
      CourseSidebar,
      CurriculumTab,
      ReviewTab,
      MemberTab,
   },
   props:{
      detail:Object
   }
};
</script>

