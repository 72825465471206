<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img width="150" :src="`${footerBgTwo ? require('../../assets/dma_logo_white.png') : require('../../assets/dma_logo_white.png')}`" alt="">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
                        <p>{{ $t('address') }}</p>

                        <div class="footer__social">
                           <ul>
                              <li><a href="https://www.facebook.com/Delkhiinmedleg">FB</a></li>
                              <!-- <li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li> -->
                              <!-- <li><a href="https://www.youtube.com/@user-hh9tj7qs1b" class="pin">YOU</a></li> -->
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">{{ $t('var18') }}</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/">{{ $t('company_nvvr') }}</a></li>
                              <li><a href="/vision">{{$t('var8')}}</a></li>
                              <li><a href="/mision">{{ $t('company_Purpose') }}</a></li>
                              <li><a href="/value">{{ $t('company_values') }}</a></li>
                              <li><a href="/instructor">{{ $t('company_taniltsuulga') }}</a></li>
                              <li><a href="/rules">{{ $t('var9') }}</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">{{ $t('var19') }}</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/matTopic">{{ $t('main') }}</a></li>
                              <li><a href="/engTopic">{{ $t('main1') }}</a></li>
                              <li><a href="/news">{{ $t('var10') }}</a></li>
                              <li><a href="/blog">{{ $t('var11') }}</a></li>
                              <li><a href="/interview">{{ $t('main2') }}</a></li>
                              <li><a href="/questions">{{ $t('asked_answer') }}</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <div class="footer__widget footer__pl-70 mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">{{ $t('contact_us') }}</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__subscribe-2' : 'footer__subscribe'}`">
                           <form action="#">
                              <div class="footer__subscribe-input mb-15">
                                 <input type="email" :placeholder="$t('contact_us1')">
                                 <button type="submit">
                                    <i class="far fa-arrow-right"></i>
                                    <i class="far fa-arrow-right"></i>
                                 </button>
                              </div>
                           </form>
                           <p>{{ $t('var12') }}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>© 2025 {{ $t('var15') }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
   }
};
</script>
