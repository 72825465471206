<template>
  <Header header__white="header__white" />
  
  <section class="page__title-area page__title-height-2 page__title-overlay d-flex align-items-center" :style="{background:'url('+require('../assets/img/page-title/page-title-3.jpg')+')',backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}">
   <div class="page__title-shape">
      <img class="page-title-shape-1" src="../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-2" src="../assets/img/page-title/page-title-shape-2.png" alt="">
      <img class="page-title-shape-3" src="../assets/img/page-title/page-title-shape-3.png" alt="">
      <img class="page-title-shape-4" src="../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-10 col-xl-10 col-lg-10 ">
            <div class="page__title-wrapper mt-110">
               <h3 v-if="this.$i18n.locale === 'mn'" class="page__title-2">{{blog.title.mn}}</h3> 
               <h3 v-if="this.$i18n.locale === 'en'" class="page__title-2">{{blog.title.en}}</h3> 
               <div class="blog__meta d-flex align-items-center">
                  <div class="blog__author d-flex align-items-center mr-40">
                     <div class="blog__author-thumb mr-10">
                        <img :src="blog.authorImg" alt="">
                     </div>
                     <div class="blog__author-info blog__author-info-2">
                        <h5 v-if="this.$i18n.locale === 'mn'">{{blog.authorName.mn}}</h5>
                        <h5 v-if="this.$i18n.locale === 'en'">{{blog.authorName.en}}</h5>
                     </div>
                  </div>
                  <div class="blog__date blog__date-2 d-flex align-items-center">
                     <i class="fal fa-clock"></i>
                     <span>{{blog.date}}</span>
                  </div>
               </div>                      
            </div>
         </div>
      </div>
   </div>
</section>

   <section class="blog__area pt-120 pb-120">
      <div class="container">
         <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12">
               <div class="blog__wrapper">
                  <div class="blog__text mb-40">
                     <p>Та мөсөн уулыг юун дээр тогтдог юм бол гэж гайхаж байсан уу? Уг уул нь танд харагдаж байгаагаасаа хамаагүй асар том хэсэг дээр тогтож байдаг. Далай доор хэсэг нь дээр харагдаж байгаа хэсгээсээ хэд дахин том байдаг гэдгийг та доорх зургаас харж болно.</p>
                  </div>
                  <!-- <div class="blog__quote grey-bg mb-45 p-relative fix">
                     <img class="quote" src="../assets/img/blog/quote-1.png" alt="">
                     <blockquote>
                        <p>After I started learning design with Quillow, I realized that I had Improved to very advanced levels.</p>
                        <h4>Chris Collins</h4>
                     </blockquote>
                  </div> -->
                  <div class="blog__text mb-30">
                     <p>Мөсөн уулыг хүүхдийн зан төлөвтэй харьцуулсан уран зургийг уран бүтээлч Силвиа Линч анх зурснаар хүүхэд болон эцэг эхчүүдийн мэргэжилтнүүд өөр өөрийн онолоороо ашиглаж иржээ. Ингээд та бүхэндээ Монгол хэлээрээ бэлтгэсэн Хүүхдийн мөсөн уул-аа хүргэж байна. Эцэг эхийн көүч хүний хувьд би эцэг эхчүүдээ хүүхдийн гаргаж байгаа зан авирын нөлөөнд автаж сөргөөр хариу үйлдэл үзүүлэхээсээ өмнө энэхүү зан авир хаанаас, юунаас болж гарч ирж байна вэ гэдгийг харах чадвартай болгоход тусладаг юм.</p>
                  </div>
                  <div class="blog__link mb-35">
                     <!-- <p>The little rotter bum bag a blinding shot gosh spifing butty eatonwha load of rubbish bamboozled. <a href="#"> https://educal.com/courses</a> </p> -->
                  </div>
                  <div class="blog__img w-img mb-45" >
                     <img :src="blog.blogImg" alt="">
                  </div>
                  <div class="blog__text mb-40">
                     <!-- <h3>Build a beautiful website with Quillow</h3> -->
                     <p>Мөсөн уулын тайлбар: Бидний нүдэнд харагдаж буй хүүхдийн зан авирууд бол бидэнд харагдахгүй байгаа тэрхүү мэдрэмжүүдийг бид нарт ойлгуулах гэсэн хэл гэж ойлгож болно. Жишээ нь таны хүүхэд тань руу ориллоо гэхэд үүний цаана юу нуугдаж байна вэ, яагаад миний хүүхэд орилов гэдгийг та өөрөөсөө асууж, хүүхэдтэйгээ ярилцахыг хичээж гол шалтгааныг олох нь таныг хүүхдийн зан авираас болж стресстэх байдлыг багасгахаас гадна та хүүхдэдээ уураар биш өрөвч сэтгэлээр хариу үйлдэл үзүүлэхэд туслана. Цаашлаад үүнийг та өөртөө дадал болгож чадвал хүүхдийн тань тантай харилцах харилцаа сайжирч, танд итгэх итгэл өсөх юм. Дээрх мөсөн ууланд би зөвхөн жишээ болгож цөөхөн зан авир болон мэдрэмжүүдийг оруулсан. Та өөрийнхөө хүүхдийн мөсөн уулыг хүүхдийнхээ гаргадаг зан авирууд дээр тулгуурлан хийж үзээрэй. Хүүхэдтэйгээ ярилцаад хийвэл илүү үр дүнтэй.</p>

                     <p><b>Ээж аавын көүч Өлзий <br>
                        Эх сурвалж: Good Parents MN</b></p>
                  </div>
                  <div class="blog__line"></div>
             
                  <div class="blog__recent mb-65">
                     <div class="section__title-wrapper mb-40">
                        <h2 class="section__title">{{ $t('var63') }} <span class="yellow-bg-sm">{{ $t('var11') }} <img src="../assets/img/shape/yellow-bg-4.png" alt="">  </span></h2>
                     </div>
                     <div class="row">
                        <div v-for="blog in blogItems.slice(0,2)" :key="blog.id" class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="blog__item white-bg mb-30 transition-3 fix">
                              <div class="blog__thumb w-img fix">
                                 <router-link :to="`/blog-details/${blog.id}`">
                                       <img :src="blog.blogImg" alt="">
                                 </router-link>
                              </div>
                              <div class="blog__content">
                                 <div class="blog__tag">
                                    <a href="#" :class="blog.color">{{blog.category}}</a>
                                 </div>
                                 <h3 class="blog__title">
                                     <router-link v-if="this.$i18n.locale === 'mn'" :to="`/blog-details/${blog.id}`">{{blog.title.mn}}</router-link>
                                     <router-link v-if="this.$i18n.locale === 'en'" :to="`/blog-details/${blog.id}`">{{blog.title.en}}</router-link>
                                 </h3>
                                 <div class="blog__meta d-flex align-items-center justify-content-between">
                                    <div class="blog__author d-flex align-items-center">
                                       <div class="blog__author-thumb mr-10">
                                          <img :src="blog.authorImg" alt="">
                                       </div>
                                       <div class="blog__author-info">
                                          <h5 v-if="this.$i18n.locale === 'mn'">{{blog.authorName.mn}}</h5>
                                          <h5 v-if="this.$i18n.locale === 'en'">{{blog.authorName.en}}</h5>
                                       </div>
                                    </div>
                                    <div class="blog__date d-flex align-items-center">
                                       <i class="fal fa-clock"></i>
                                       <span>{{blog.date}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- BlogRightSide start -->
            <!-- BlogRightSide end -->
         </div>
      </div>
    </section>
  <Footer footerPadding="true" />
</template>

<script>
import BlogItemMixin from '../mixins/blogMixin';
import Header from '../components/Home/Header.vue';
import Footer from '../components/Home/Footer.vue';

export default {
  name: "BlogDetails",
  mixins: [BlogItemMixin],
  components:{
     Header,
     Footer,
  },
    data() {
        return {
            id: this.$route.params.id,
            blog: {}
        }
    },
    methods: {
        getBlog(blogId) {
            this.blog = this.blogItems.find(item => item.id == blogId);
        }
    },
    created() {
        this.getBlog(this.id);
    }
};
</script>
