<template>
    <Header header__white="header__white" />
    <Breadcrumb :title="$t('asked_answer')" :subtitle="$t('asked_answer')" />
    <Events/>]
    <Footer footerPadding="true"/>
  </template>
  
  <script>
  import Header from '../components/Home/Header.vue';
  import Breadcrumb from '../components/common/Breadcrumb.vue';
  import Footer from '../components/Home/Footer.vue';
  import Events from '../components/Home/Events.vue';
  
  export default {
     name:'BlogPage',
     components:{
        Header,
        Breadcrumb,
        Events,
        Footer,
     }
  }
  </script>