export default {
    data() {
        return {
           blogItems:  [
               {
                   id: 1,
                   blogImg:(`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Musun+uul_nuur.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: {
                     mn: 'Хүүхдийн мөсөн уул',
                     en: 'Children`s Iceberg'
                   },
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: {
                     mn: 'А. Болортуяа',
                     en: 'Bolortuya. A'
                   },
                //    category: 'Art & Design',
                   date: '2024-2-14',
                   color:'green'
               },
               {
                   id: 2,
                   blogImg: (`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Udur+buriin+nar_nuur.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: {
                     mn: 'Өдөр бүрийн нар',
                     en: 'Every day sun'
                   },
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: {
                     mn: 'А. Болортуяа',
                     en: 'Bolortuya. A'
                   },
                //    category: 'UX Design',
                   date:'2024-2-14',
                   color:'sky-blue'
               },
               {
                   id: 3,
                   blogImg:(`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Huuuhed+tanii+geriin+toli.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: {
                     mn: 'Хүүхэд танай гэр бүлийн толь',
                     en: 'A child is a mirror of your family'
                   },
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: {
                     mn: 'А. Болортуяа',
                     en: 'Bolortuya. A'
                   },
                //    category: 'Development',
                   date:'2024-2-14',
                   color:'green',
               }
           ]
        }
    }
}