<template>
  <div>
    <Header header__white="header__white" />
   <Breadcrumb :title="$t('var17')" :subtitle="$t('var17')" />
       <div class="row">
         <div class="col-xxl-4 offset-xxl-4">
            <div class="section__title-wrapper mb-60 mt-60 text-center">
            </div>
         </div>
      </div>
      <section class="container value__area pt-50 pb-100">
         <div class="value_story">
            <b style="font-size:30px">{{ $t('var16') }}</b>
         </div>
      </section>
       <Footer footerPadding="true" />
  </div>
</template>

<script>
   import Header from '../components/Home/Header.vue';
   import Breadcrumb from '../components/common/Breadcrumb.vue';
   import Footer from '../components/Home/Footer.vue';
export default {
    name:"MisionPage",
    components:{
        Header,
        Breadcrumb,
        Footer
    }
}
</script>

<style>

</style>