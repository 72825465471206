<template>
  <section class="blog__area pt-120 pb-120">
      <div class="container">
               <div class="row">
                  <div v-for="blog in blogItems" :key="blog.id" 
                  class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                     <div class="blog__wrapper">
                        <div class="blog__item white-bg mb-30 transition-3 fix">
                           <div class="blog__thumb w-img fix">
                              <router-link :to="`/blog-details/${blog.id}`">
                                 <img :src="blog.blogImg" alt="">
                              </router-link>
                           </div>
                           <div class="blog__content">
                              <h3 class="blog__title">
                                 <router-link v-if="this.$i18n.locale === 'mn'" :to="`/blog-details/${blog.id}`">{{blog.title.mn}}</router-link>
                                 <router-link v-if="this.$i18n.locale === 'en'" :to="`/blog-details/${blog.id}`">{{blog.title.en}}</router-link>
                              </h3>
   
                              <div class="blog__meta d-flex align-items-center justify-content-between">
                                 <div class="blog__author d-flex align-items-center">
                                    <div class="blog__author-thumb mr-10">
                                       <img :src="blog.authorImg" alt="">
                                    </div>
                                    <div class="blog__author-info">
                                       <h5 v-if="this.$i18n.locale === 'mn'">{{blog.authorName.mn}}</h5>
                                       <h5 v-if="this.$i18n.locale === 'en'">{{blog.authorName.en}}</h5>
                                    </div>
                                 </div>
                                 <div class="blog__date d-flex align-items-center">
                                    <i class="fal fa-clock"></i>
                                    <span>{{blog.date}}</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
         </div>
      </div>
   </section>
</template>

<script>
import BlogMixin from '../../mixins/blogMixin';
import axios from 'axios'
import config from '@/config/index'

export default {
   name:'BlogArea',
   mixins:[BlogMixin],
   components:{
   },
   created(){
      this.getBlogs()
   },
   data(){
      return {
         blogs: []
       }
   },
   methods:{
      getBlogs(){
            var self = this
            axios.get(config.BASE_SERVER_PATH + '/getBlogsWeb')
               .then(function (response) {
                  self.blogs = response.data.data
               })
               .catch(function (error) {
                  console.log(error);
               });
      }
   }
};
</script>
