<template>
   <section class="page__title-area page__title-height page__title-overlay d-flex align-items-center"
    :style="'background-image:url(' + returnBackgroundImgUrl() + ')'">
      <div class="container">
         <div class="row">
            <div class="col-xxl-12">
               <div class="page__title-wrapper mt-110">
                  <h3 class="page__title">{{title}}</h3>                         
                  <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                           <router-link to="/">{{ $t('company_nvvr') }}</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                        {{subtitle}}</li>
                     </ol>
                  </nav>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'PageTitle',
   props:{
      title:String,
      subtitle:String,
   },
   data(){
      return{
         url_img :'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/Screen+Shot+2022-12-03+at+9.06.20+PM.png'
      }
   },
   created(){
      console.log(this.$route.path)
   },
   methods:{
      returnBackgroundImgUrl(){
         if(this.$route.path == '/instructor' || this.$route.path == '/rules'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/DSC_9790.JPG'
         }
         if(this.$route.path == '/vision'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/vision-v3.jpg'
         }
         if(this.$route.path == '/blog'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/Blog-v7.jpg'
         }
         if(this.$route.path == '/contact'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/contact%D0%B5-v2.jpg'
         }
         if(this.$route.path == '/news'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/News-v4.jpg'
         }
         if(this.$route.path == '/questions'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/question-v4.jpg'
         }
         if(this.$route.path == '/interview'){
            return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/review-v4.jpg'
         }else{
         return 'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/Screen+Shot+2022-12-03+at+9.20.55+PM.png'
         }
      }
   }
};
</script>

