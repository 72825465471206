<template>
<section class="testimonial__area testimonial__overlay pt-40 pb-40 " style="background-image: url('https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/2_1.jpg')">
   <div class="container">
   <div class="col-xxl-12">
   <div class="testimonial__slider swiper-container">
       <swiper
        ref="mySwiper"
        :modules="modules"
        :loop="true"
        :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
      >
        <swiper-slide v-for="testimonial in testimonialData" :key="testimonial.id">
          <div class="testimonial__slider-inner swiper-wrapper">
               <div class="testimonial__item text-center swiper-slide">
                       <div class="testimonial__thumb">
                     <img :src="testimonial.img_url" alt="">
                  </div>
                  <div class="testimonial__content">
                     <p v-if="this.$i18n.locale === 'en'">{{testimonial.comments.en}}</p>
                     <p v-if="this.$i18n.locale === 'mn'">{{testimonial.comments.mn}}</p>
                     <div class="testimonial__info">
                        <h4 v-if="this.$i18n.locale === 'en'">{{testimonial.user_name.en}}</h4>
                        <h4 v-if="this.$i18n.locale === 'mn'">{{testimonial.user_name.mn}}</h4>
                        <span v-if="this.$i18n.locale === 'en'">{{testimonial.social_channel.en}}</span>
                        <span v-if="this.$i18n.locale === 'mn'">{{testimonial.social_channel.mn}}</span>
                     </div>
                  </div>
               </div>
            </div>
        </swiper-slide>

         <div class="swiper-button-next swiper-nav">
         <i class="far fa-arrow-right"></i>
      </div>
      <div class="swiper-button-prev swiper-nav">
         <i class="far fa-arrow-left"></i>
      </div>
      </swiper>

     

         </div>
      </div>
   </div>
</section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade } from "swiper";

export default {
   name:'TestimonialArea',
   data () {
      return {
         testimonialData:[
            {
               id:1,
               comments:{
                  en: '“A great man is an ordinary man who has tried his best”',
                  mn: '“Аугаа хүн гэдэг нь хамгийн их хичээж чадсан энгийн хүн юм”'
               },
               user_name:{
                  en: 'Molor-Erdene. A',
                  mn: 'А.Молор-Эрдэнэ'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/319970395_717816036578016_772949684823845560_n.png",
               social_channel: {
                  mn: 'Математикийн дасгалжуулагч',
                  en: 'Mathematics Coach'
               }
            },
            {
               id:2,
               comments:{
                  en: '“A man who conquers others is called a strong person, a man who conquers himself is called a wise person”',
                  mn: '“Бусдыг дийлдэг хүнийг хүчтэй, өөрийгөө дийлдэг хүнийг ухаантай гэдэг”'
               },
               user_name:{
                  en: 'Bilguun. B',
                  mn: 'Б.Билгүүн'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Bilgvvn.JPG",
               social_channel:{
                  en: 'English Language Coach ',
                  mn: 'Англи хэлний дасгалжуулагч'
               }
            },
            {
               id:3,
               comments:{
                  en: '“You are right if you think you can`t, you can`t. You are also right if you think you can, you can”',
                  mn: '“Чи чадахгүй гэж бодож байвал чиний зөв. Чи чадахгүй. Харин чи чадна гэж бодож байвал бас л чиний зөв. Чи чадна”'
               },
               user_name: {
                  en: 'Altanzaya. N ',
                  mn: 'Н.Алтанзаяа'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Altanzaya.JPG",
               social_channel:{
                  en: 'Mathematics Coach',
                  mn: 'Математикийн дасгалжуулагч'
               }
            },
            {
               id:4,
               comments:{
                  en: '“Start at the moment you think you can`t. Soon that thought will go away”',
                  mn: '“Би чаддаггүй гэж бодох тэр үед эхэл. Удахгүй тэр бодол алга болно”'
               },
               user_name:{
                  en: 'Munkhzaya. G ',
                  mn: 'Г.Мөнхзаяа'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Monhzaya.JPG",
               social_channel:{
                  en: 'Mathematics Coach',
                  mn: 'Математикийн дасгалжуулагч'
               }
            },
            {
               id:5,
               comments:{
                  en: '“I strongly believe in the power of luck. I observed that the harder you work, the luckier you can  become“',
                  mn: '“Би азын тэнгэр байдагт бүрэн итгэдэг. Их ажиллах тусам их аз дайрдагийг би ажигласан”'
               },
               user_name:{
                  en: 'Khosbayar. B',
                  mn: 'Б.Хосбаяр'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Hosbayar.JPG",
               social_channel:{
                  en: 'English Language Coach ',
                  mn: 'Англи хэлний дасгалжуулагч'
               }
            },
            {
               id:6,
               comments:{
                  en: '“Starting is the hardest decision to make. The rest is just consistency“',
                  mn: '“Хамгийн хэцүү шийдвэр нь хийж эхлэх. Бусад нь бол ердөө л тууштай зан”'
               },
               user_name: {
                  en: 'Bolortuya. A',
                  mn: 'А.Болортуяа'
               },
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Bolortuya.JPG",
               social_channel:{
                  en: 'Mathematics Coach',
                  mn: 'Математикийн дасгалжуулагч'
               }
            }
         ]
      }
   },
   components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, EffectFade ],
    };
  },
};
</script>
