<template>
  <div>
    <Header header__white="header__white" />
   <Breadcrumb :title="$t('var8')" :subtitle="$t('var8')" />
       <div class="row">
         <div class="col-xxl-4 offset-xxl-4">
            <div class="section__title-wrapper mb-60 mt-60 text-center">
               <!-- <h2 class="section__title">Алсын хараа </h2> -->
               <!-- <p>No contracts. No surprise fees.</p> -->
            </div>
         </div>
      </div>
      <div class="row mb-100 mt-50">
         <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div class="price__tab-content">
               <div class="tab-content">
                  
                  <div class="tab-pane fade show active">
                     <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-6 col-md-6">
                           <div class="price__item grey-bg mb-30 p-relative">
                              <div class="price__tag mb-0">
                                 <h4>{{ $t('var13') }}<br/>{{ $t('var14') }}</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
            </div>
         </div>
      </div>
       <Footer footerPadding="true" />
  </div>
</template>

<script>
   import Header from '../components/Home/Header.vue';
   import Breadcrumb from '../components/common/Breadcrumb.vue';
   import Footer from '../components/Home/Footer.vue';
export default {
    name:"VisionPage",
    components:{
        Header,
        Breadcrumb,
        Footer
    }
}
</script>

<style>

</style>