import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/scss/bootstrap.scss';
import router from './router';
import 'swiper/css/bundle';
import 'bootstrap';
import store from '@/store/index'
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/scss/main.scss';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from './i18n';


let app = createApp(App);
app.use(store)
app.use(router)
app.use(BootstrapVue3);
app.use(i18n);
app.use(ElementPlus)
app.mount('#app');
