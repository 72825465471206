<template>
<section class="teacher__area pt-50 pb-110">
   <div class="container">
      <div class="row">
         <div class="col-xxl-12">
            <div class="section__title-wrapper text-center mb-60">
               <p class="section_information">{{ $t('var27') }}</p>
            </div>
         </div>
      </div>
      <!-- <h2 class="section__title text-center pb-80">Хамт олон<br></h2>
      <div class="row content-center justify-content-around">
         <div v-for="instructor,index in instructorData" :key="index" 
         class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="teacher__item text-center grey-bg-5 transition-3 mb-30">
               <div class="teacher__thumb w-img fix">
                  <a href="#">
                     <img v-if="instructor.img_url" :src="instructor.img_url" alt="">
                     <img v-else src="https://media.istockphoto.com/photos/female-portrait-icon-as-avatar-or-profile-picture-picture-id477333976?k=20&m=477333976&s=612x612&w=0&h=3OaYU8gCXxKjdzdKUc9MokXV8q0vAVk2J3dBtabmtpE=" alt="">
                  </a>
               </div>
               <div class="teacher__content">
                  <h3 class="teacher__title">{{instructor.name}}</h3><br/>
                  <span v-if="instructor.position"> {{instructor.position.position_name}}</span>
                  <div class="teacher__social">
                     <ul>
                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div> -->
   </div>
</section>
</template>

<script>
import axios from 'axios'
import config from '@/config/index'
export default {
   name:'InstructorArea',
   data () {
        return {
            instructorData:[]
        }
    },
    created(){
       this.getEmplooyeesData()
    },
    methods:{
       getEmplooyeesData () {
          var self = this
      axios.get(config.BASE_SERVER_PATH + '/getUserListWeb')
      .then(function (response) {
         self.instructorData = response.data.result
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    }
};
</script>
