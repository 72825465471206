<template>
  <EventDetailsTitle :eventHeadDetail="detail"/>
   <section class="event__area pb-110">
    <div class="container">
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12">
                <div class="events__wrapper">
                <div class="events__details mb-35">
                    <h3 v-if="this.$i18n.locale === 'mn'">Дэлгэрэнгүй</h3>
                    <h3 v-if="this.$i18n.locale === 'en'">More detail</h3>

                    <p v-if="this.$i18n.locale === 'mn' && detail.id === 1">5-р анги төгссөн сурагчид Шинэ эрин, Шинэ эхлэл сургуулиудад. Дээрх сургуулиудад 3-р ангиас эхлэн бэлдэнэ</p>
                    <p v-if="this.$i18n.locale === 'en' && detail.id === 1">Students who have completed their 5th grades will be qualified to study at New Era and New Beginning schools. They will be prepared for these schools starting from their 3rd grades.</p>

                    <p v-if="this.$i18n.locale === 'mn' && detail.id === 2">Тогтмол бэлтгэж байгаа. Эрт бэлтгэж эхлэх тусам илүү амжилт үзүүлэх боломжтой.</p>
                    <p v-if="this.$i18n.locale === 'en' && detail.id === 2">We are preparing for it regularly. The earlier the student starts, the more chance the student will have to succeed.</p>

                    <p v-if="this.$i18n.locale === 'mn' && detail.id === 3">Монгол улсын дотоодын болон ОУ-ын нэр хүнд бүхий олимпиадуудад суралцагчдыг амжилттай бэлтгэсээр байна.</p>
                    <p v-if="this.$i18n.locale === 'en' && detail.id === 3">We prepare the students successfully for the prestigious domestic and international competitions in Mongolia.</p>
                </div>
                <div class="events__tag">
                </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import EventDetailsTitle from './EventDetailsTitle.vue';
import axios from 'axios';
import config from '@/config/index'
export default {
    name:'EventDetailsArea',
    components:{
        EventDetailsTitle,
    },
    data(){
        return {
            detail:{}
        }
    },
    created(){
        this.getFrequentlyQuestionByID()
    },
    methods:{
        getFrequentlyQuestionByID(){
         var self = this
         var payload = {
            id : self.$route.query.id
         }
         axios.post(config.BASE_SERVER_PATH + '/getFrequentlyQuestionsById', payload)
         .then(function (response) {
            console.log(response.data.data)
            self.detail = response.data.data
         })
         .catch(function (error) {
            console.log(error);
         });
        }
    }
}
</script>

