import { createI18n } from 'vue-i18n';

// Орчуулгын файлууд
const messages = {
  en: {
    home: "Home",
    about_us: "About us",
    academics: "Academics",
    news: "News",
    contact_us: "Contact us",
    mendchilgee:"Good day to you!",
    more:"More",
    math_khutulbur:"Standard Mathematics Program",
    level: "Level improvement",
    level_up: "Level",
    geometr: "Geometry",
    khutulbur_main: "Advanced Program",
    khutulbur_main1: "General",
    khutulbur_main2: "General knowledge",
    khutulbur_main3: "Geometry",
    exam_dotood: "Preparing for domestic Specialized exams",
    exam_dotood1: "Preparing for the admission exams of schools with International programs /New era, New Beginning schools/ ",
    exam_dotood2: "Preparing for General Entrance Exam",
    exam_gadaad:"Preparing for International Specialized exams",
    exam_gadaad1:"Preparing for the exams of International  Government Scholarship",
    exam_gadaad2:"Preparing for the International exams of schools with International programs ",
    higher_math_khutulbur1:"Applied Mathematics 1, 2, 3",
    higher_math_khutulbur2:"Economic mathematics",
    higher_math_khutulbur3:"Engineering mathematics",
    name_tuya:'Bolortuya.A',
    english_khutulbur1:"Standard Program",
    english_khutulbur3:"Advanced Program",
    english_khutulbur4:"9th grade Enrollment",
    english_khutulbur5:"General Entrance Exam",
    english_khutulbur6:"High School /Lycee/",
    international_khutulbur:"International Program",
    international_khutulbur1:"IELTS",
    international_khutulbur2:"IGCSE ",
    asked_answer:"Frequently asked questions and answers",
    asked_answer1:"Do you prepare the students for New era and New Beginning schools?",
    asked_answer2:"Do you prepare the students for Mongol Aspiration school?  ",
    asked_answer3:"Do you prepare the students for International and domestic competitions? ",
    help:"WE WILL HELP YOU ",
    bagsh_helsen_vg:"“You can spend a night without having any money today. Spend your last dime on your child's education so that there won’t be another 100 days to spend without money in the future.“",
    name_kob: "Khosbayar. B",
    name_bilguun: "Biguun. B",
    name_altanzaya: "Altanzaya. N",
    name_munkhzaya: "Munkhzaya. G",
    address:"Ulaanbaatar city, Bayangol district, 2nd khoroo, Peace Avenue, West 4th road, “Gem Palace" + "center, 13th floor",
    company_nvvr:"Home page",
    company_Purpose:"Purpose",
    company_values:"Values",
    company_taniltsuulga:"Introduction",
    company_etic:"Ethical Codes",
    main:"Mathematics Curriculum",
    main1:"English Language Curriculum",
    main2:"Interview",
    main3:"Frequently asked questions and answers",
    contact_us1:"Write your phone number here.",
    contact_us2:"We will contact you as soon as possible",
    tugeemel: "Frequently",
    answe_questions: "asked questions and answers",
    maths: "Mathematics",
    coach: "coach",
    bolortuya: "Bolortuya. A",
    hello: "Good day to you!",
    mathsLessons: "Mathematics",
    curriculum: "Curriculum",
    advenced: "Advanced",
    curriculumDan: "Curriculum",
    preparing_for_international_specialized_exams: "Preparing for International Specialized exams",
    higher_mathematics_program: "Higher Mathematics Program",
    preparing1: "Preparing for the admission exams of schools with International programs /New era, New Beginning schools/ ",
    preparing2: "Preparing for the exams of International  Government Scholarship",
    var1: "Applied Mathematics 1, 2, 3",
    var2: "English Language",
    var3: "We will help you.",
    var4: "See all",
    var5: "Education is a priority.",
    var6: "There is not any child who doesn't understand.",
    var7: "“it is only about the time they spent without understanding. The most important thing is to ignite that spark within the children.“",
    var8: "Vision",
    var9: "Ethical Codes",
    var10: "News",
    var11: "Blog",
    var12: "We will contact you as soon as possible.",
    var13: "PREPARING FOR THE FUTURE",
    var14: "OF THE WORLD",
    var15: "Global Knowledge Academy",
    var16: "Our goal is to promote and enhance the intellectual potential of Mongolians in the world.",
    var17: "Mission",
    var18: "Company",
    var19: "Menu",
    var20: "Vlues",
    var21: "Mongolian Wisdom",
    var22: "Boundless Mentality",
    var23: "A team united by one purpose",
    var24: "Consistency",
    var25: "Humanity",
    var26: "Mathematics training",
    var27: "Since its establishment in 1998, the “Global Knowledge“ Academy has been working hard to improve the standards of mathematics teaching methods and to bring the intellectual abilities of Mongolian students to world-class levels. We have been continuously operating for 25 years by preparing the youths for entrance exams of Cambridge programs, General Entrance Exams in Mongolia, for mathematics and English language courses at top international universities, and have successfully graduated about 25 thousand students so far. Our selective program, based on our 25 years of experience, is unique because it expands the intellectual and mental scope of children within a short time. In the future, we will continue to work hard to eliminate any academic delays of students who study mathematics, and we will keep opening the doors for our youth by helping them to pass the entrance exams successfully, to achieve scholarships, and to study at low cost at the top universities in the world.",
    var28:"Ethical codes of Global Knowledge Academy",
    var29:"Ethical Codes of Global Knowledge Academy aims to define the ethical standards and rules that our employees must follow in their daily activities.",
    var30:"We adhere to the Universal Declaration of Human Rights of United Nations as our ethical standards and aim to respect human rights without violating them in any ways.	",
    var31:"We treat all teachers, staff, and students with equal respect, regardless of race, age, gender, citizenship, ethnicity, religion, or sexual orientation.	",
    var32:"We do not tolerate any form of insult, ridicule, bullying, intimidation, or harassment of others and respect the dignity of all the individuals.",
    var33:"We create an environment for every teacher and employee to develop and improve themselves by providing them equal opportunities.	",
    var34:"We work hard to be good teachers, employees, and managers, as well as good leaders, by developing ourselves and setting a good example for others.	",
    var35:"We are always looking for new ideas and opportunities to improve ourselves. Therefore, we always try to be open-minded about accepting new ideas and more creative approaches.	",
    var36:"We protect the reputation and property of the academy and complete our tasks with a sense of responsibility.",
    var37:"We value honesty and friendliness in our interactions with the students, the parents, and the guardians.	",
    var38:"If anyone who works in the academy experiences any health issues or discomfort, they should notify the other staff immediately and take the necessary measures.	",
    var39:"Personal information can not be disclosed and may be provided if required by authorized officials or organizations in accordance to laws.",
    var40:"We do not allow anyone to use their position or duties to gain financial or other benefits, either directly or indirectly.",
    var41:"We do not participate in politics on behalf of our Academy and do not donate to any politician or political force by violating the laws of Mongolia.",
    var42:"We do not participate in politics on behalf of our Academy and do not donate to any politician or political force by violating the laws of Mongolia.",
    var43:"We protect our intellectual property strictly and monitor whether others are using it without any permission. We also respect the intellectual property of others and use it only with official permission when it is necessary.	",
    var44:"We always comply with the laws of Mongolia and the Ethical Codes of the Global Knowledge Academy on a daily basis.",
    var45:"The implementation of these codes is monitored by the Internal Audit Department and the Executive Director respectively. Based on the conclusions of the Internal Audit Department or the officials who are responsible for enforcing these Ethical Codes, the employee who violates the Ethical Codes will be subject to disciplinary action by order of the Executive Director. If the violation is considered a crime, the case will be reported to the relevant legal and supervisory authorities.	",
    var46:"Mathematics Standard Program",
    var47:"A guest of “Saturn” Talk Show",
    var48:"Teacher:",
    var49:"Frequently question",
    var50:"Date:",
    var51:"Location:",
    var52:"Ulaanbaatar",
    var53:"Feedback",
    var54:"If you have any questions, please write them down.",
    var55:"Your name",
    var56:"Your phone number",
    var57:"Write your questions here.",
    var58:"Send",
    var59:"Location",
    var60:"Email",
    var61:"Phone",
    var62:"Follow Us",
    var63:"Other",
    var64:"Affects children's learning psychology",
    var65:"Choose the 3 most important factors?",
    var66:"Parents",
    var67:"Teacher",
    var68:"The student himself",
    var69:"",
    var70:"",
    var71:"",
    var72:"",
    var73:"",
    var74:"",
    var75:"",
    var76:"",
    var77:"",
    var78:"",
    var79:"",
    var80:"",
    var81:"",
    var82:"",
    var83:"",
    var84:"",
    var85:"",
    var86:"",
    var87:"",
    var88:"",
    var89:"",
    var90:"",
    var91:"",
    var92:"",
    var93:"",
    var94:"",
    var95:"",
    var96:"",
    var97:"",
    var98:"",
    var99:"",
    var100:"",
    var101:"",
    var102:"",
    var103:"",
    var104:"",
    var105:"",
    var106:"",
    var107:"",
    var108:"",
    var109:"",
    var110:"",
    var111:"",
    var112:"",
    var113:"",
    var114:"",
    var115:"",
    var116:"",
    var117:"",
    var118:"",
    var119:"",
    var120:"",
    var121:"",
    var122:"",
    var123:"",
    var124:"",
    var125:"",
  },
  mn: {
    home: "Нүүр",
    about_us: "Бидний тухай",
    academics: "Сургалт",
    news: "Мэдээ мэдээлэл",
    contact_us: "Холбоо барих",
    mendchilgee:"Танд энэ өдрийн мэнд хүргэе! ",
    more:"Дэлгэрэнгүй",
    math_khutulbur:"Математикийн стандарт хөтөлбөр",
    level: "Түвшин нөхөх",
    level_up: "Түвшин ахиулах",
    geometr: "Геометр",
    khutulbur_main1: "Ерөнхий эрдэм",
    khutulbur_main2: "Мэргэжлийн курс",
    khutulbur_main3: "Геометр",
    exam_dotood: "Дотоодын төрөлжсөн шалгалтанд бэлтгэх",
    exam_dotood1: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх",
    exam_dotood2: "ЭЕШ-нд бэлтгэх",
    exam_gadaad:"ОУ-н төрөлжсөн шалгалтанд бэлтгэх",
    exam_gadaad1:"ОУ-н Засгийн газрын тэтгэлэгт шалгалтанд бэлтгэх",
    exam_gadaad2:"ОУын хөтөлбөрт сургуулиудын ...",
    higher_math_khutulbur1:"Хэрэглээний математик 1, 2, 3",
    higher_math_khutulbur2:"Эдийн засгийн математик",
    higher_math_khutulbur3:"Инженерийн математик",
    name_tuya:'А. Болортуяа',
    english_khutulbur1:"Стандарт хөтөлбөр",
    english_khutulbur3:"Гүнзгийрүүлсэн хөтөлбөр",
    english_khutulbur4:"9-р ангийн элсэлт",
    english_khutulbur5:"ЭЕШ",
    english_khutulbur6:"Лицей",
    international_khutulbur:"Олон улсын хөтөлбөр",
    international_khutulbur1:"IELTS",
    international_khutulbur2:"IGCSE ",
    asked_answer:"Түгээмэл асуулт, хариулт",
    asked_answer1:"Шинэ эрин, Шинэ эхлэл сургуулиудад бэлддэг үү?",
    asked_answer2:"Монгол тэмүүлэл сургуульд бэлддэг үү?",
    asked_answer3:"Гадаад дотоодын олимпиадуудад бэлддэг үү? ",
    help:"Бид танд туслах болно",
    bagsh_helsen_vg:"“Өнөөдөр та хоосон хонож болно. Ирээдүйд хоосон хонох 100 өдөр битгий ирээсэй гэж Эцсийн сохор зоосоо хүүхдийнхээ боловсролд зарцуул.“",
    name_kob: "Б.Хосбаяр",
    name_bilguun: "Б.Билгүүн",
    name_altanzaya: "Н.Алтанзаяа",
    name_munkhzaya: "Г.Мөнхзаяа",
    address:"Улаанбаатар хот, Баянгол дүүрэг, 2-р хороо, Энхтайваны өргөн чөлөө, Баруун 4-н зам, “Жем палас" + "төв, 13 давхарт",
    company_nvvr:"Нүүр хуудас",
    company_Purpose:"Зорилго",
    company_values:"Үнэт зүйлс",
    company_taniltsuulga:"Танилцуулга",
    company_etic:"Ёс зүйн дүрэс",
    main:"Математикийн хөтөлбөр",
    main1:"Англи хэлний хөтөлбөр",
    main2:"Ярилцлага",
    main3:"Түгээмэл асуулт, хариулт",
    contact_us1:"Та утасны дугаараа үлдээнэ үү.",
    contact_us2:"Бид таньлуу аль болох хурдан холбоо барих болно",
    tugeemel: "Түгээмэл",
    answe_questions: "асуулт, хариулт",
    maths: "Математикийн",
    coach: "дасгалжуулагч",
    bolortuya: "А.Болортуяа",
    hello: "Танд энэ өдрийн мэндийг хүргэе!",
    mathsLessons: "Математикийн хичээлийн",
    curriculum: "сургалтын хөтөлбөр",
    advenced: "Гүнзгийрүүлсэн",
    curriculumDan: "хөтөлбөр",
    preparing_for_international_specialized_exams: "ОУ-ын төрөлжсөн шалгалтанд бэлтгэх",
    higher_mathematics_program: "Дээд математикийн хөтөлбөр",
    preparing1: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх (Шинэ эрин, Шинэ эхлэл)",
    preparing2: "ОУ-ын Засгийн газрын тэтгэлэгт шалгалтанд бэлтгэх",
    var1: "Хэрэглээний математик 1,2,3",
    var2: "Англи хэлний хичээлийн",
    var3: "Бид таньд туслах болно.",
    var4: "Бүгдийг харах",
    var5: "Боловсрол нэн тэргүүн",
    var6: "Ойлгохгүй хүүхэд гэж байхгүй.",
    var7: "“Ойлгоогүй өнгөрүүлсэн цаг хугацаа л гэж байдаг. Хамгийн гол нь хүүхдүүдийн доторх очыг асааж, хөгжүүлэх нь л чухал.“",
    var8: "Алсын хараа",
    var9: "Ёс зүйн дүрэм",
    var10: "Мэдээ",
    var11: "Нийтлэл",
    var12: "Бид таньлуу аль болох хурдан холбоо барих болно.",
    var13: "ДЭЛХИЙН ИРЭЭДҮЙД",
    var14: "БЭЛДЭНЭ",
    var15: "Дэлхийн мэдлэг акедеми",
    var16: "Монгол хүний оюуны чадамжийг дэлхийд таниулах, үнэлэмжийг нэмэгдүүлэх нь бидний эрхэм зорилго",
    var17: "Эрхэм зорилго",
    var18: "Компани",
    var19: "Цэс",
    var20: "Үнэт зүйл",
    var21: "Монгол ухаан",
    var22: "Хязгааргүй сэтгэлгээ",
    var23: "Зорилгод нэгдсэн баг",
    var24: "Тууштай байдал",
    var25: "Хүнлэг чанар",
    var26: "Математикийн сургалт",
    var27: "“Дэлхийн мэдлэг“ академи нь 1998 онд байгуулагдсан цагаасаа математикийн хичээлийн заах арга зүйн стандартыг дээшлүүлэх, Монгол хүүхдийн оюуны чадамжийг дэлхийн түвшинд хүргэхийн төлөө ажилласаар эдүгээ 25 дэх жилдээ тасралтгүй үйл ажиллагаа явуулж байна. Бид хүүхэд, залуусыг Кэмбрижийн хөтөлбөртэй сургууль, ЭЕШ болон олон улсын шилдэг их, дээд сургуулиудын элсэлтийн шалгалтад математик, англи хэлний хичээлүүдээр бэлтгэж өдийг хүртэл давхардсан тоогоор 25 мянга орчим суралцагчдыг амжилттайгаар төгсгөөд байна. Бидний 25 жилийн туршлагад суурилсан сонгомол хөтөлбөр нь хүүхдийн оюуны болон сэтгэлгээний цар хүрээг богино хугацаанд тэлж өгдгөөрөө онцлогтой. Цаашид хүүхдүүдийн математикийн хичээлийн хоцрогдлыг арилгахын төлөө шамдан ажиллаж, дэлхийн шилдэг их, дээд сургуулиудын элсэлтэд өөрийн мэдлэгээрээ тэнцэх, тэтэглэгт хүрэх, бага зардлаар суралцах гарцуудыг хүүхэд, залууст нээж өгсөөр байх болно.",
    var28:"Дэлхийн мэдлэг академийн ёс зүйн дүрэм",
    var29:"Дэлхийн мэдлэг академийн Ёс зүйн дүрэм Дэлхийн мэдлэг академийн ажилтнуудын өдөр тутмын үйл ажиллагаандаа баримтлах ёс зүйн хэм хэмжээг тодорхойлж өгөх үндсэн зорилготой.",
    var30:"Бид Нэгдсэн Үндэстний Байгууллагын Хүний эрхийн түгээмэл тунхаглалыг ёс зүйн хэм, хэмжээ болгон баримталж, хүний эрхийг хүндэтгэж ямар нэг байдлаар зөрчихгүй байхыг эрмэлздэг.",
    var31:"Бид арьсны өнгө, нас, хүйс, иргэний харьяалал, үндэс угсаа, шашин шүтлэг, бэлгийн чиг хандлагаас нь үл хамааран бүх багш, ажилтан, суралцагчтай эн тэнцүү хүндэтгэж харьцдаг.",
    var32:"Бид бусдыг ямар ч хэлбэрээр доромжлох, тохуурхах, дээрэлхэх, айлган сүрдүүлэх, дарамтлах явдлыг хүлээн зөвшөөрдөггүй бөгөөд хувь хүний нэр төрд хүндэтгэлтэй ханддаг.",
    var33:"Багш, ажилтан бүрд хөгжиж, ахиж дэвших орчныг бүрдүүлж, ижил тэгш бололцоо олгоно.",
    var34:"Бид сайн багш, ажилтан, менежер төдийгүй сайн манлайлагч байхыг эрмэлзэж, өөрийгөө болон бусдыг хөгжүүлэж бусдадаа үлгэр дууриал үзүүлнэ.",
    var35:"Бид шинийг сэдэж, аливааг сайжруулах боломжийг цаг ямагт эрэлхийлнэ. Тиймээс бид шинэ санаа, бүтээлч хандлагыг ямагт дэмжинэ.",
    var36:"Бид академийн нэр хүнд, өмч хөрөнгийг хайрлан хамгаалж ажилдаа эзний сэтгэлээр хандана.",
    var37:"Бид суралцагч, эцэг эх, асран хамгаалагч нартай үнэнч шударга, найрсаг занг эрхэмлэн харилцана.",
    var38:"Академийн байранд хэн нэгэнд эрүүл мэндийн холбогдолтой ямар нэг өвдөлт, зовиур илэрсэн бол холбогдох ажилтнуудад нэн даруй мэдэгдэж, шаардлагатай арга хэмжээг яаралтай авах үүрэгтэй.",
    var39:"Хувь хүний мэдээллийг задруулдаггүй бөгөөд хуулийн дагуу эрх бүхий байгууллагаас шаардсан тохиолдолд өгч болно.",
    var40:"Бид аливаа зар сурталчилгааг хууль, тогтоомжийн хүрээнд явуулах ба худал мэдээлэл агуулсан, буруу ойлголт төрүүлэхүйц зар сурталчилгаа явуулдаггүй.",
    var41:"Бид албан тушаал, ажил үүргээ ашиглан санхүүгийн болон ил далд ямар нэг байдлаар өөртөө давуу байдал бий болгохыг зөвшөөрдөггүй.",
    var42:"Бид Академийн нэр ашиглан улс төрд оролцдоггүй мөн Монгол Улсын хууль тогтоомжоос гадуур аль нэг улс төрч, улс төрийн хүчинд хандив өгдөггүй.",
    var43:"Бид өөрийн оюуны өмчийг хамгаалж бусад этгээд зөвшөөрөлгүйгээр ашиглаж буй эсэхэд хяналт тавих ба бусдын оюуны өмчийг ч хүндэтгэж, шаардлагатай тохиолдолд албан ёсны зөвшөөрөл авсны үндсэн дээр ашиглана.",
    var44:"Бид Монгол Улсын хууль тогтоомж, Дэлхийн мэдлэг академийн Ёс зүйн дүрмийг өдөр тутам мөрдөж ажиллана.",
    var45:"Энэхүү дүрмийн хэрэгжилтэд дотоод хяналтын алба болон Гүйцэтгэх захирал хяналт тавьж ажиллана. Ёс зүйн дүрмийг мөрдүүлэх, болзошгүй зөрчлийг шийдвэрлэх дотоод хяналтын алба буюу албан тушаалтны гаргасан дүгнэлтийг үндэслэн зөрчил гаргасан ажилтанд Гүйцэтгэх захирлын тушаалаар сахилгын шийтгэл ногдуулах, Ёс зүйн зөрчил нь гэмт хэргийн шинжтэй бол холбогдох хууль, хяналтын байгууллагад шилжүүлнэ. ",
    var46:"Математик хөтөлбөр",
    var47:"Санчир гарагийн зочин",
    var48:"Багш:",
    var49:"Түгээлмэл асуулт хариулт",
    var50:"Огноо:",
    var51:"Байршил:",
    var52:"Улаанбаатар",
    var53:"Санал хүсэлт",
    var54:"Таньд асууж тодруулах зүйл байвал бичиж үлдээнэ үү?",
    var55:"Таны нэр",
    var56:"Таны утасны дугаар",
    var57:"Асуух гэж буй зүйлээ бичнэ үү",
    var58:"Илгээх",
    var59:"Хаяг байршил",
    var60:"Мэйл хаяг",
    var61:"Утас",
    var62:"Биднийг дагаарай",
    var63:"Бусад ",
    var64:"Хүүхдийн суралцах сэтгэлзүйд нөлөөлдөг",
    var65:"хамгийн чухал 3-н хүчин зүйлийг сонгоно уу?",
    var66:"Эцэг эх",
    var67:"Багш",
    var68:"Сурагч өөрөө",
    var69:"",
    var70:"",
    var71:"",
    var72:"",
    var73:"",
    var74:"",
    var75:"",
    var76:"",
    var77:"",
    var78:"",
    var79:"",
    var80:"",
    var81:"",
    var82:"",
    var83:"",
    var84:"",
    var85:"",
    var86:"",
    var87:"",
    var88:"",
    var89:"",
    var90:"",
    var91:"",
    var92:"",
    var93:"",
    var94:"",
    var95:"",
    var96:"",
    var97:"",
    var98:"",
    var99:"",
    var100:"",
    var101:"",
    var102:"",
    var103:"",
    var104:"",
    var105:"",
    var106:"",
    var107:"",
    var108:"",
    var109:"",
    var110:"",
    var111:"",
    var112:"",
    var113:"",
    var114:"",
    var115:"",
    var116:"",
    var117:"",
    var118:"",
    var119:"",
    var120:"",
    var121:"",
    var122:"",
    var123:"",
    var124:"",
    var125:"",
  },
};

const i18n = createI18n({
    locale: 'mn', // Set default language
    fallbackLocale: 'mn',
  messages,
});

export default i18n;
