<template>
   <Header header__white="header__white" />
   <Breadcrumb :title="$t('news')" :subtitle="$t('news')" />
   <div class="service_detail_body m-b-100 inline_height" v-for="(detail, index) in detailList" :key="index">
      <div v-if="lesson == detail.value">
         <h2 v-if="this.$i18n.locale === 'en'" class="section__title service_detail_body_title">{{detail.title.en}}</h2>
         <h2 v-if="this.$i18n.locale === 'mn'" class="section__title service_detail_body_title">{{detail.title.mn}}</h2>
         <div v-if="this.$i18n.locale === 'en' && detail.description" class="service_detail_description m-t-50">{{detail.description.en}}</div>
         <div v-if="this.$i18n.locale === 'mn' && detail.description" class="service_detail_description m-t-50">{{detail.description.mn}}</div>
         <el-row :gutter="18" class="row-bg m-t-50" justify="space-evenly">
            <el-col :span="6" v-for="(sub,i) in detail.detailItems" :key="i">
               <div class="service_detail_item box_height">
                  <span v-if="this.$i18n.locale === 'en'" class="service_detail_item_title">{{sub.item_title.en}}</span>
                  <span v-if="this.$i18n.locale === 'mn'" class="service_detail_item_title">{{sub.item_title.mn}}</span>
                  <div class="service_detail_info">
                     <ul>
                        <li class="align-items-center card_service_icon">
                           <i class="icon-check-sign"></i>
                           <span v-if="this.$i18n.locale === 'en'" class="m-l-10">{{sub.class_name.en}}</span>
                           <span v-if="this.$i18n.locale === 'mn'" class="m-l-10">{{sub.class_name.mn}}</span>
                        </li>
                        <span v-if="this.$i18n.locale === 'en'">{{sub.item_description.en}}</span>
                        <span v-if="this.$i18n.locale === 'mn'">{{sub.item_description.mn}}</span>
                     </ul>
                  </div>
               </div>
            </el-col>
         </el-row>
      </div>
   </div>
   <Footer footerPadding="true" />
</template>

<script>
   import Header from '../components/Home/Header.vue';
   import Breadcrumb from '../components/common/Breadcrumb.vue';
   import Footer from '../components/Home/Footer.vue';

   export default {
      name: 'BlogPage',
      components: {
         Header,
         Breadcrumb,
         Footer,
      },
      data() {
         return {
            lesson: 'm1',
            detailList: [
            {
               value: 'm1',
               title: {
                  en: "Mathematics Standard Program",
                  mn: "Математикийн стандарт хөтөлбөр"
               },
               description: {
                  mn: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
                  en: "It does not apply to the students who are in the advanced program. If they want to, the students can advance their studies after they complete this level and meet the requirements for the advanced program. This program is designed to teach the students based on their personal speed of learning and intellectual capacity."
               },
               detailItems: [{
                     item_title: {
                        en: "Level Improvement",
                        mn: "Түвшин нөхөх"
                     },
                     class_name: {
                        mn: "3-12 анги",
                        en: "Grades 3-12"
                     },
                     item_description: {
                        mn: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуулийн стандарт хөтөлбөр бүхий ангид суралцдаг сурагчдын хичээлийн агууллагын дутуу орхигдсон, ойлгогдоогүй сэдвүүдээ бүрэн нөхөж ойлгож авах боломжтой.",
                        en: "By studying in this level/group, students who study at any General Standard of Secondary school can fully understand the topics they could not understand previously."
                     }
                  },
                  {
                     item_title: {
                        mn: "Түвшин ахиулах",
                        en: "Level Advancement"
                     },
                     class_name: {
                        mn: "3-12 анги",
                        en: "Grades 3-12"
                     },
                     item_description: {
                        mn: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуульд суралцаж буй түвшнөөсөө дээш ангийн түвшинд мэдлэгийг олж авах болно.",
                        en: "By studying in this level/group, the students can gain knowledge above their current level of grade at any General Standard of schools."
                     },
                  },
                  {
                     item_title: {
                        mn: "Геометр",
                        en: "Geometry "
                     },
                     class_name: {
                        mn: "3-12 анги",
                        en: "Grades 3-12"
                     },
                     item_description: {
                        mn: "Тус сургалт нь математикийн хичээлээс тусдаа сэдэв боловч анги ангийн түшинд хавсран заавал судалсан байх шаардлагатай хичээл тул анги бүрийн алгебрийн хичээлийн хажуугаар хавсран судлах шаардлагатай.",
                        en: "This course is a separate one from mathematics. However, it is a mandatory course that must be studied in conjunction with other Algebra courses for each grade."
                     },
                  }
               ]
            },
            {
               value: 'm2',
               title: {
                  mn: "Математикийн гүнзгийрүүлсэн хөтөлбөр",
                  en: "Mathematics Advanced Program"
               },
               description: {
                  mn: "Энэхүү хичээлүүдийн агуулга нь математикийн ШУныг дагнан судлах гэж буй болон цаашид цэвэр математикт суурилсан мэргэжлүүдээр мэргэшихээр төлөвлөж буй суралцагчдын судлах агуулгуудыг багтаасан. Энэхүү сургалтанд суралцагчдын оюуны потенциалын чадамж өндөр байхыг шаардана.",
                  en: "The content of these courses are suitable for students who are going to study mathematics exclusively and those who plan to specialize in majors that are based on mathematics. This course requires the students to have high level of intellectual potential."
               },
               detailItems: [{
                     item_title: {
                        mn: "Ерөнхий эрдэм",
                        en: "General knowledge"
                     },
                     class_name: {
                        mn: "5-12 анги",
                        en: " Grades 5-12"
                     },
                     item_description: {
                        mn: "Уг хичээл нь анги бүрийн түвшин дэх стандарт агуулгыг илүү задаргаатайгаар болон илүү логик хүндрүүлсэн талдаа үзэж судална. Энэ хөтөлбөрийг 1, 11 р сургууль болон энгийн сургуулиудын математикийн гүнзгийрүүлсэн сургалттай ангид суралцагч сурагчид үзэж судална.",
                        en: "This course will examine the standard content of each grade level in a more detailed and challenging ways. This program will be studied by the  students who are in grades between 1 and 11. Students who are in advanced mathematics classes at schools are qualified to study this program, too."
                     }
                  },
                  {
                     item_title: {
                        mn: "Мэргэжлийн курс",
                        en: "Professional course"
                     },
                     class_name: {
                        mn: "5-12 анги",
                        en: " Grades 5-12"
                     },
                     item_description: {
                        mn: "Энэхүү хичээл нь хичээлийн үндсэн хөтөлбөрөөс гадна илүү академик гүнзгийрүүлсэн хүнд түвшний бодлогуудыг агуулах бөгөөд гүнзгийрүүлэн судалдаг суралцагчид нэмэлтээр бүх төрүлийн математикийн тэмцээн уралдаанд орох, сэтгэн бодох чадварыг мэргэшүүлэн судлахад суралцдаг.",
                        en: "This course includes more academically advanced, more challenging problems in addition to the core curriculum, and it is also suitable for the students who  participate in Mathematics competitions for all-ages because this program helps them to develop their thinking skills."
                     }
                  },
                  {
                     item_title: {
                        mn: "Геометр",
                        en: "Geometry"
                     },
                     class_name: {
                        mn: "5-12 анги",
                        en: "Grades 5-12"
                     },
                     item_description: {
                        mn: "Уг геометрийн хичээл нь стандарт бүх агуулгыг багтаах боловч түлхүү теорем, чанаруулын баталгаа нотолгоо болон сэтгэлгээ судалгаа илүү шаардсан  хүндрүүлсэн түвшинд судална. Математикийн ШУ наар дагнан мэргэшихээр төлөвлөж буй хэн бүхний заавал судлах хичээл.",
                        en: "This geometry course covers all the standard content and additionally focuses on theorems, proofs of quality, and more advanced levels of mind researching. It is a required course for anyone who is planning to major in Mathematics."
                     }
                  }
               ]
            },
            {
               value: 'm3',
               title: {
                  mn: "Дотоодын төрөлжсөн шалгалтанд бэлтгэх хөтөлбөр",
                  en: "Program to prepare for Specialized Exams /Domestic/"
               },
               description: {
                  mn: "Энэхүү хөтөлбөрт хамрагдах суралцагч нь стандарт түвшний хоцрогдолгүй байхыг шаардах бөгөөд мөн суралцах хугацаатай хангалттай байх шаардлагатай",
                  en: "Students who are enrolled in this program are required to be at or above the standard level and have sufficient time to study."
               },
               detailItems: [{
                     item_title: {
                        mn: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх хөтөлбөр 1 (Шинэ эрин, Шинэ эхлэл)",
                        en: "Preparing for the admission exams to study at International schools - Program 1 (New Era, New Beginning schools)"
                     },
                     class_name: {
                        mn: "3-5 анги",
                        en: "Grades 3-5"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь 3 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана.",
                        en: "This program can be studied starting from the 3rd grade and requires the students to not have any academic delay in their current grades, to have good reading skills, to have high comprehension skills, to avoid being dependent on screens, and to have good time management skills."
                     }
                  },
                  {
                     item_title: {
                        mn: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх2 ( Монгол тэмүүлэл)",
                        en: "Preparing for admission exams to enroll in International Program 2 (Mongol aspiration school)"
                     },
                     class_name: {
                        mn: "7-8 анги",
                        en: "7-8th grade"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь 6 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана.",
                        en: "This program can be studied starting from the 6th grade and requires the students to be at the level they are currently studying, to not have any academic delay in their current grades, to have good reading skills, to have high comprehension skills, to avoid being dependent on screens, and to have good time management skills."
                     }
                  },
                  {
                     item_title: {
                        mn: "Математикийн гүнзгийрүүлсэн сургуульд бэлтгэх (1,11 дүгээр сургууль болон бүх сургуулийн математикийн гүнзгийрүүлсэн ангид бэлтгэх)",
                        en: "Preparing the students to enroll in schools with advanced mathematics program (school Number 1, 11 and all other schools)"
                     },
                     class_name: {
                        mn: "3-5 анги",
                        en: "Grades 3-5"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь 3 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана.",
                        en: "This program can be studied starting from the 3rd grade and requires the students to be at the level they are currently studying, to not have any academic delay in their current grades, to have good reading skills, to have high comprehension skills, to avoid being dependent on screens, and to have good time management skills."
                     }
                  },
                  {
                     item_title: {
                        mn: "ЭЕШ-нд бэлтгэх",
                        en: "Preparing for the General Entrance Exam"
                     },
                     class_name: {
                        mn: "11-12 анги, насанд хүрэгчид",
                        en: " Grades 11-12 and adults"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь суралцагчийг суралцаж буй ангийн түвшинд хүртэл алгебр болон геометрийн хичээлийнх ямар нэгэн хоцрогдолгүй байхыг шаардах бөгөөд хэрэв хоцрогдолтой байгаа нөхцөлд түвшин нөхөх сургалтанд хамрагдсаны дараагаар цэвэр ЭЕШ ийн бэлтгэл сургалтанд хамрагдах боломжтой.",
                        en: "This program requires the students to be good at algebra or geometry and if they have some academic delay, they can enroll in this course after improving their  current levels."
                     }
                  }
               ]
            },
            {
               value: 'm4',
               title: {
                  mn: "Олон улсын төрөлжсөн шалгалтанд бэлтгэх хөтөлбөр",
                  en: "Program to prepare for Specialized Exams /International/"
               },
               description: {
                  mn: "Энэхүү хөтөлбөрт хамрагдах суралцагч англи хэлний дундаас дээш түвшний мэдлэгтэй уншаад бүрэн ойлгох чадвартай, ангийн түвшинд стандарт хөтөлбөрийн хоцрогдолгүй байх шаардлагатай.",
                  en: "Students who enrolled in this program must have Intermediate or above level of English language and they must be able to read and comprehend fully by not having any academic delay in their current level of grades."
               },
               detailItems: [{
                     item_title: {
                        mn: "ОУ-ын Засгийн газрын тэтгэлэгт шалгалтанд бэлтгэх",
                        en: "Preparing for the International Government Scholarship Exam"
                     },
                     class_name: {
                        mn: "11-12 анги",
                        en: "Grades 11-12"
                     },
                     item_description: {
                        mn: "БНХАУ, ОХУ, Япон, Солонгос гэх мэт улсуудаас зарлагддаг засгийн газрын тэтгэлэгт шалгалтуудад улс орон болгоных нь шалгалтын онцлогт тохируулсан хөтөлбөрөөр бэлтгэдэг.",
                        en: "Countries such as China, Russia, Japan, and Korea announce Government scholarship programs annually and the students are prepared with a program designed to meet the requirements of each country."
                     }
                  },
                  {
                     item_title: {
                        mn: "ОУын хөтөлбөрт сургуулиудын сурагчдын өгдөг шалгалтуудад бэлтгэх",
                        en: "Preparing for the exams taken by students who study at schools with International programs"
                     },
                     class_name: {
                        mn: "8-12 анги. насанд хүрэгчид",
                        en: "Grades 8-12 and adults"
                     },
                     item_description: {
                        mn: "Олон улсын хөтөлбөр бүхий сургуулиудын суралцагчдын өгдөг IGCSE, AS level, A lelev, Statistics болон бакалавр магистрын түвшинд элсэн суралцагчдын өгдөг SAT, GRE, GMAT шалгалтуудад бэлтгэх хөтөлбөр.",
                        en: "It is a preparation program designed for exams such as IGCSE, AS level, A level, Statistics, SAT, GRE, and GMAT exams for students who enrolled in international programs. Undergraduate and graduate students are also eligible to study this program."
                     }
                  }
               ]
            },
            {
               value: 'm5',
               title: {
                  mn: "Дээд математикийн хөтөлбөр",
                  en: "Higher Mathematics Program"
               },
               description: {
                  mn: "Эдгээр хөтөлбөрүүд нь зөвхөн монгол улсад төдийгүй олон улсад ижил хөтөлбөрийн дагуу явагддаг бөгөөд гадны аль ч сургуульд дээрх мэргэжлээр суралцаж байгаа оюутнууд монгол хэлээрээ заалгуулах боломжтой. Энэхүү хөтөлбөр хамрагдах сурагчид ЕБС-ын математик болон геометрийн хичээлийн хоцрогдолгүй байх шаардлагатай.",
                  en: "This program is taught by following one curriculum all over the world and students who are studying at any schools abroad  can be taught in Mongolian language through this program. Students who are participating in this program must not have any academic delay in mathematics and geometry subjects at their high schools."
               },
               detailItems: [{
                     item_title: {
                        mn: "Хэрэглээний математик 1,2,3",
                        en: "Applied Mathematics 1,2,3"
                     },
                     class_name: {
                        mn: "Насанд хүрэгч",
                        en: "Adult"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь математикийн төрөлжсөн бус ч математикийн суурь хичээл болон ордог их сургуулийн 1, 2 р курсын оюутнууд суралцах боломжтой.",
                        en: "This program is open to freshmen and sophomore students of universities who are not specialized in mathematics, but are taking a basic mathematics course."
                     }
                  },
                  {
                     item_title: {
                        mn: "Эдийн засгийн математик",
                        en: "Economic mathematics"
                     },
                     class_name: {
                        mn: "Насанд хүрэгч",
                        en: "Adult"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь их сургуульд эдийн засаг, санхүү , менежментийн чиглэлээр суралцаж байгаа оюутнуудад зориулагдсан.",
                        en: "This program is designed for students who are studying economics, finance, and management courses at universities."
                     }
                  },
                  {
                     item_title: {
                        mn: "Инженерийн математик",
                        en: "Engineering mathematics"
                     },
                     class_name: {
                        mn: "Насанд хүрэгч",
                        en: "Adult"
                     },
                     item_description: {
                        mn: "Энэхүү хөтөлбөр нь их сургуульд инженер чиглэлээр суралцаж байгаа оюутнуудад зориулагдсан.",
                        en: "This program is designed for students who are studying engineering courses at universities."
                     }
                  }
               ]
            },{
                   value: 'e1',
                   title: {
                     mn: "Англи хэлний стандарт хөтөлбөр",
                     en: "Standard English Program"
                   },
                   detailItems: [{
                         item_title: {
                           mn: "Түвшин ахиулах",
                           en: "Level Advancement "
                         },
                         class_name: {
                           mn: "A1,A2",
                           en: "A1,A2"
                         },
                         item_description: {
                           mn: "Анхан шатны ойлголт, Анхан шатны хэрэглээ, Англиар сэтгэх Үндсэн 12 дүрэм, Шинэ үгс.",
                           en: "Basic understanding and usage of 12 basic rules in English language, vocabulary"
                         }
                      },
                      {
                         item_title: {
                           mn: "Түвшин ахиулах",
                           en: "Level Advancement"
                         },
                         class_name: {
                           mn: "B1,B2",
                           en: "B1,B2"
                         },
                         item_description: {
                           mn: "Дунд болон Анхан дунд шат, Шинжлэх ухааны үгс болон ярианы ашиглалт.",
                           en: "Intermediate and pre-intermediate levels, Scientific Vocabulary and Speech Usage."
                         }
                      },
                      {
                         item_title: {
                           mn: "Түвшин ахиулах",
                           en: "Level Advancement"
                         },
                         class_name: {
                           mn: "C1,C2",
                           en: "C1,C2"
                         },
                         item_description: {
                           mn: "Ахисан болон гүнзгий Академик түвшний үгсийн бичгийн ярианы хэрэглээ.",
                           en: "Written and spoken usage of advanced and academic level of vocabulary."
                         }
                      }
                   ],
            },
            {
               value: 'e2',
               title: "Англи хэлний гүнзгийрүүлсэн хөтөлбөр",
            //  description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
               detailItems: [{
                     item_title: {
                        mn: "9-р ангийн элсэлт",
                        en: "9th grade"
                     },
                     class_name: {
                        mn: "A1,A2 B1,B2",
                        en: "A1,A2 B1,B2"
                     },
                     item_description: {
                        mn: "МУ ын ЕБС ийн 9-р ангид элсэн ороход бэлтгэх.",
                        en: "Preparing for admission exams to enroll in 9th grade at schools in Mongolia. "
                     }
                  },
                  {
                     item_title: {
                        mn: "ЭЕШ",
                        en: "General Entrance Exam"
                     },
                     class_name: {
                        mn: "A2-C1",
                        en: "A2-C1"
                     },
                     item_description: {
                        mn: "Монгол улсын их сургуулиудын шалгалтад бэлтгэх анги.",
                        en: "Preparing the students who want to enroll in universities of Mongolia. "
                     }
                  },
                  {
                     item_title: {
                        mn: "Лицей",
                        en: "High School /Lycee/ "
                     },
                     class_name: {
                        mn: "A2-C1",
                        en: "A2-C1"
                     },
                     item_description: {
                        mn: "Их сургуулиудын харьяа ахлах сургуулиудад бэлтгэх.",
                        en: "Preparing the students who want to study at high schools that are affiliated with universities in Mongolia."
                     }
                  }
               ],
            },{
               value: 'e3',
               title: {
                  mn: "Англи хэлний олон улсын хөтөлбөр",
                  en: "International English Language Program"
               },
               //description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
               detailItems: [{
                     item_title: {
                        mn: "IELTS",
                        en: "IELTS"
                     },
                     class_name: {
                        mn: "B1,2-C1,2",
                        en: "B1,2-C1,2"
                     },
                     item_description: {
                        mn: "Англи хэлний хэрэглээний ур чадвар шалгах шалгалтад бэлдэх.",
                        en: "Preparing the students for the English proficiency test."
                     }
                  },
                  {
                     item_title: {
                        mn: "Түвшин",
                        en: "Levels"
                     },
                     class_name: {
                        mn: "B1,2-C1,2",
                        en: "B1,2-C1,2"
                     },
                     item_description: {
                        mn: "Кэмбриджийн сургалттай сургуулиудад IGCSE хөтөлбөрт бэлтгэх сургалт.",
                        en: "Preparing the students for IGCSE program at schools with Cambridge Program."
                     }
                  }
               ],
            },
      ],
         }
      },
      created() {
         this.renderView()
      },
      methods: {
         renderView() {
            if (this.$route.params.data) {
               this.lesson = this.$route.params.data
            } else {
               this.$router.push('/home')
            }
         },
      }
   }
</script>