<template>
<section class="teacher__area pt-160 pb-110">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="../../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="../../assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-3" src="../../assets/img/page-title/page-title-shape-3.png" alt="">
      <img class="page-title-shape-7" src="../../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
            <div class="teacher__details-thumb p-relative w-img pr-30">
               <img src="../../assets/img/about/about.jpeg" alt="">
               <div class="teacher__details-shape">
                  <img class="teacher-details-shape-1" src="../../assets/img/teacher/details/shape/shape-1.png" alt="">
                  <img class="teacher-details-shape-2" src="../../assets/img/teacher/details/shape/shape-2.png" alt="">
               </div>
            </div>
         </div>
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="teacher__wrapper">
               <div class="teacher__top d-md-flex align-items-end justify-content-between">
                  <div class="teacher__info">
                     <h4>{{ $t('bolortuya') }}</h4>
                     <span>Математикийн ахлах дасгалжуулагч</span>
                  </div>
                  <div class="teacher__rating">
                     <!-- <h5>Review:</h5> -->
                     <!-- <div class="teacher__rating-inner d-flex align-items-center">
                        <ul>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                        </ul>
                        <p>4.5</p>
                     </div> -->
                  </div>
                  <div class="teacher__social-2">
                     <h4>Дагах:</h4>
                     <ul>
                        <li>
                           <a href="#">
                              <i class="fab fa-facebook-f"></i>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <i class="fab fa-twitter ms-2"></i>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <i class="fab fa-vimeo-v ms-2"></i>
                           </a>
                        </li>
                        <li>
                           <a href="#">
                              <i class="fab fa-linkedin-in ms-2"></i>
                           </a>
                        </li>
                     </ul>
                  </div>
                  <div class="teacher__follow mb-5">
                     <a href="#" class="teacher__follow-btn">{{ $t('contact_us') }} 
                        <!-- <i class="fal fa-plus ms-2"></i> -->
                     </a>
                  </div>
               </div>
               <div class="teacher__bio">
                  <h3>{{ $t('hello') }}</h3>
                  <p>"Дэлхийн мэдлэг" академийг миний аав Ц. Амарсайхан асан (математикч, физикч) 1998 онд үүсгэн байгуулсан бөгөөд тэр үеэс одоог хүртэл үйл ажиллагаагаа тасралтгүй явуулж 25 дахь жилтэйгээ золгож байна.

 Хүмүүс математикийг нэмж, хасах, үржих, хуваах үйлдэл л гэж боддог. Угтаа математик нь ойлгох шинжлэх ухаан юм.
Аливаа асуудлыг аль ч талаас нь харж, ойлгож, эцсийн дүндээ ийм байх ёстой гэдэг зөв гаргалгааг гаргах л зорилготой. 
Мэдлэгийг хэзээ л бол хэзээ олж уншаад, нөхөж болно. Үүнээс чухал нь хүүхдийг хүсэл тэмүүлэлтэй болгох нь чухал.
  Бид сургалтынхаа эхний хоёр долоо хоногт хүүхдэд "Би өөрөө л хичээлээ, даалгавраа хийх хэрэгтэй. Энэ цагт дуусгаж амжуулахгүй бол цаг хугацаагаа алдаж яваа юм байна” гэдэг бодол, дадлыг суулгадаг. 
Яваандаа хичээлдээ төвлөрч сууж сурна, үүнийг ойлгохгүй л бол дараа дараагийн хичээлийг сурахгүй, надад сурсан зүйл үлдэхгүй нь ээ” гэдгээ өөрөө ухамсарлаж мэдэрч эхэлдэг. Ингэж ухамсартайгаар сурч ойлгосон зүйл насан туршид нь үлдэнэ. Харин автоматаар цээжилсэн зүйл маргааш, нөгөөдөр гэхэд л мартагдчихна.
Ойлгогдоогүй орхигдсон хичээл нь анги дэвших бүрд нөхөхөд хэцүү хоцрогдол болж хүүхдийн сэтгэл зүй, мэргэжил сонголт, өөртөө итгэх итгэл, ирээдүйн амьдралын чанарт нь сөргөөр нөлөөлөх өндөр магадлалтай байдаг.
Хичээлийн хоцрогдлыг аль болох бага ангид нь нөхөж чадвал богино хугацаанд үр дүнд хүрнэ.
Эцэг эх бүр л “Хүүхдийнхээ төлөө амьдарч байна” гэж хэлдэг. Гэсэн хэдий ч хүүхдэдээ зориулсан шаардлагатай цаг хугацааг гаргаж, анхаарал тавьж чаддаггүй. Уг нь хичээвэл хүүхдүүдэд маш их боломж байдаг. Хүүхэд бүр өөр өөр сурах арга барилтай, аргыг нь олоод заавал математикийг ойлгодоггүй хүүхэд гэж байхгүй, ойлгуулаагүй өнгөрсөн цаг хугацаа л гэж бий. 

Мянга мянган Монгол хүний оюуны чадамж дэлхийд үнэлэгдэх болтугай!
</p>
               </div>
               <div class="teacher__courses pt-55">
                  <!-- <div class="section__title-wrapper mb-30">
                     <h2 class="section__title">Teacher <span class="yellow-bg yellow-bg-big">Course<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                  </div> -->
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
import CourseMixin from '../../mixins/courseItemsMixin';

export default {
   name:'InstructorDetailsArea',
   mixins:[CourseMixin],
};
</script>

