<template>
<section class="hero__area hero__height hero__height-2 d-flex align-items-center blue-bg-3 p-relative fix">
   <div class="hero__shape">
      <!-- <img class="hero-1-circle" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-circle-2" src="../../assets/img/shape/hero/hero-1-circle.png" alt="">
      <img class="hero-1-dot-2" src="../../assets/img/shape/hero/hero-1-dot-2.png" alt=""> -->
   </div>
   <div class="container">
      <div class="hero__content-wrapper mt-80">
         <div class="row align-items-center">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__content hero__content-2 p-relative z-index-1">
                           <img 
                           class="main_logo m-l-40"
                  src="../../assets/dma_logo_horzintal.png" height="300" width="300"  alt="logo" />
                  <!-- <h3 class="hero__title hero__title-2">
                     Дэлхийн  мэдлэг <br>
                    академи
                  </h3> -->
                  <h4 style="text-transform: uppercase;
                  /* padding: 10px; */
                  width: 400px;
                  font-weight: 500;
                  margin-top: -50px;
                  margin-left: 40px;
                  margin-bottom:50px;
                  border-radius: 5px;
                  font-family: 'Geologica', sans-serif;
                  color: #6b6a6a;">{{ $t('var8') }}</h4>
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
               <div class="hero__thumb-wrapper mb--120">
                  <div class="hero__thumb-2 scene">
                     <img class="hero-big" src="../../assets/img/hero/hero-1.png" width="110%" alt="">
                     <img class="hero-shape-purple" src="../../assets/img/hero/hero-2/hero-shape-purple.png" width="700px" alt="">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'HeroArea'
};
</script>
